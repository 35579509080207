const IconSidebarElviria: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
    >
      <path
        d="M20.2251 41.6176C18.4311 40.1558 17.2894 38.436 16.0662 36.4583C15.4138 35.3404 13.9459 33.5347 11.6626 33.5347C9.95008 33.5347 8.40067 34.4805 7.50364 36.0283C6.60661 37.5761 6.60661 39.4679 7.42209 41.0156C7.50364 41.1876 7.58519 41.3596 7.66674 41.4456C9.86853 45.315 12.9674 48.4106 16.8817 50.6463C20.8775 52.882 25.5257 53.9998 30.8264 53.9998C34.8222 53.9998 38.5734 53.3119 41.9984 51.9361C43.4663 51.3342 44.9341 50.4743 46.3205 49.4425C47.299 48.7546 47.8699 47.6367 48.033 46.4329C48.1961 45.1431 47.7883 43.9392 47.0544 42.9934C46.2389 42.0475 45.0972 41.4456 43.874 41.4456C43.0585 41.4456 42.3246 41.7035 41.6722 42.1335C38.5734 44.1112 35.0668 45.1431 31.1525 45.1431C26.9121 45.1431 23.1608 43.9392 20.2251 41.6176Z"
        fill="white"
      />
      <path
        d="M51.0475 13.844C49.0088 10.0605 46.1546 6.9649 42.4849 4.72921C40.3647 3.43939 37.8367 2.49352 35.0641 1.97759C34.8194 1.8916 34.5748 1.8916 34.3301 1.8916C32.1283 1.8916 30.3343 3.78334 30.3343 6.10502C30.3343 8.08275 31.6391 9.80251 33.4331 10.2324C35.9611 10.8344 38.0813 11.8662 39.7123 13.5C42.0772 15.7357 43.6266 18.5733 44.279 21.9268V22.0988H29.2742C27.0724 22.0988 25.2783 23.9905 25.2783 26.3122C25.2783 27.4301 25.6861 28.4619 26.42 29.2358C26.9908 29.8377 27.8063 30.2677 28.6218 30.4397C28.8664 30.5256 29.1111 30.5256 29.2742 30.5256H45.2576H45.4207H51.2106C52.76 30.5256 53.9832 29.2358 53.9832 27.688C54.1463 22.4428 53.0862 17.7994 51.0475 13.844Z"
        fill="white"
      />
      <path
        d="M22.915 0.945869C22.3441 0.343953 21.6102 0 20.7947 0H7.42086H6.93157C4.72978 0 2.93573 1.89174 2.93573 4.21342C2.93573 5.33126 3.34347 6.36312 4.0774 7.13701C4.81133 7.91091 5.7899 8.34084 6.85003 8.34084H13.3739V8.51282C12.6399 12.1243 11.0905 14.9619 8.64408 17.0256C6.93158 18.4874 4.97442 19.5193 2.85418 20.1212C1.22322 20.6371 0 22.1849 0 24.0767V24.4206C0 26.6563 1.71251 28.4621 3.83275 28.4621C4.0774 28.4621 4.32204 28.4621 4.56668 28.3761C7.17622 27.8601 9.62266 26.9143 11.7429 25.6245C15.4941 23.3888 18.4298 20.2932 20.5501 16.4237C22.6703 12.5543 23.7305 8.08288 23.7305 3.18156C23.7305 2.32168 23.4858 1.54779 22.915 0.945869Z"
        fill="#47C8CB"
      />
    </svg>
  );
};

export default IconSidebarElviria;
