import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router";
import promptsSlice from "store/reducers/prompts.reducer";
import MainTemplate from "templates/MainTemplate";
import { fetchHistoryFlowsSearch } from "api/tasks";
import { makeStyles } from "@material-ui/core/styles";
import { ResponseFetchHistoryFlowsSearch } from "entities/tasks/TaskEntity";
import { formatDate } from "utils";
import ReactJson from "react-json-view";
import Loader from "components/atoms/icons/Loader";

const useStyles = makeStyles((theme) => ({
  mainCn: {
    display: "flex",
    rowGap: "16px",
    flexDirection: "column",
  },
  tableCn: {
    width: "100%",
  },
  trCn: {
    fontWeight: 700,
  },
  headerVariablesCn: {
    padding: "0 16px",
    width: "100%",
    height: "40px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#d1d5db",
    border: "1px solid #9ca3af",
  },
  headerVariablesTextCn: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  liCn: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
  },

  mainTitle: {
    fontSize: "24px",
    marginBottom: "16px",
    fontWeight: 600,
    color: theme.palette.secondary.main,
  },
  leftBorder: {
    paddingLeft: "16px",
    marginLeft: "32px",
    borderLeft: `1px solid ${theme.palette.secondary.main}`,
  },
  activityHeaderCn: {
    display: "flex",
    flexDirection: "column",
  },
  pl32: {
    paddingLeft: "32px",
  },
  my16: {
    margin: "16px 0",
  },
  activityKey: {
    fontWeight: 600,
    fontSize: "18px",
    width: "100px",
  },
  activityValue: {
    fontSize: "18px",
  },
  activityPairCn: {
    display: "flex",
  },
}));

const TaskProcess: React.FC<
  RouteComponentProps<{
    id: string;
  }>
> = ({ match }) => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const customerId: number = +match.params.id;

  const [isLoading, setIsLoading] = useState(true);
  const [
    taskProcess,
    setTaskProcess,
  ] = useState<ResponseFetchHistoryFlowsSearch>(
    {} as ResponseFetchHistoryFlowsSearch
  );

  const fetchPastCompanyTasks = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    try {
      if (customerId) {
        const res = await fetchHistoryFlowsSearch({
          pageNo: 0,
          pageSize: 9999,
          keyLike: "master",
          withVariables: true,
          variables: {
            customerId,
          },
        });

        setTaskProcess(res);
      }
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;

      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  }, [customerId]);

  useEffect(() => {
    void fetchPastCompanyTasks();
  }, []);

  return (
    <MainTemplate>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={classes.mainCn}>
          <TableTaskProcess task={taskProcess?.processes} />
        </div>
      )}
    </MainTemplate>
  );
};

const TableTaskProcess = ({
  isRecursive = false,
  task,
}: {
  isRecursive?: boolean;
  task: any;
}) => {
  const classes = useStyles();

  return (
    <>
      {task?.map((process: any, index: number) => {
        return (
          <div key={`process-${index}`} className={classes.pl32}>
            {!isRecursive && (
              <div className={classes.mainTitle}>
                {process?.name?.charAt(0)?.toUpperCase() +
                  process?.name
                    ?.substring(1, process?.name?.length)
                    .replaceAll("_", " ")}
              </div>
            )}
            <div className={classes.leftBorder}>
              <HeaderVariables key={`header-variables-${index}`} {...process} />
              <ContentActivity activities={process?.activities} />
            </div>
          </div>
        );
      })}
    </>
  );
};

const HeaderVariables = ({ ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.my16}>
      <ReactJson
        name="Variables"
        style={{ fontSize: 12 }}
        src={props?.variables}
        collapsed={true}
        enableClipboard={false}
        displayDataTypes={false}
        displayObjectSize={false}
        quotesOnKeys={false}
      />
    </div>
  );
};

const ContentActivity = ({ activities }: { activities: Array<any> }) => {
  const classes = useStyles();

  if (activities?.length) {
    return (
      <div className={classes.pl32}>
        {activities
          ?.filter(
            (activity: any) =>
              activity.type === "callActivity" ||
              activity.type === "userTask" ||
              activity.type === "serviceTask"
          )
          .map((activity: any, index: number) => {
            return (
              <div key={`content-activity-${index}`}>
                <div className={classes.activityHeaderCn}>
                  <div className={classes.activityPairCn}>
                    <div className={classes.activityKey}>Name:</div>{" "}
                    <div className={classes.activityValue}>
                      {activity?.name}
                    </div>
                  </div>
                  <div className={classes.activityPairCn}>
                    <div className={classes.activityKey}>Type:</div>{" "}
                    <div className={classes.activityValue}>
                      {activity?.type}
                    </div>
                  </div>
                  <div className={classes.activityPairCn}>
                    <div className={classes.activityKey}>Start date:</div>{" "}
                    <div className={classes.activityValue}>
                      {formatDate(activity?.startTime, "dd/MM/yyyy HH:mm:ss")}
                    </div>
                  </div>
                </div>
                <TableTaskProcess
                  isRecursive
                  task={[activity?.calledProcess]}
                />
              </div>
            );
          })}
      </div>
    );
  } else {
    return null;
  }
};

export default TaskProcess;
